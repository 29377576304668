import React from "react";
import styles from './Search.module.scss';
import debounce from 'lodash.debounce'; //-----для отложенного выполнения поиска
import { useSelector, useDispatch } from "react-redux";
import { setSearchS1, setSearchS2 } from '../../redux/slices/filterSlice'


export const Search = () => {
    const searchValueS1 = useSelector((state) => state.filter.searchS1);

    const dispatch = useDispatch();

    const inputRef = React.useRef();
    //-----функция для очистки строки посика
    const onClickClear = () => {
        dispatch(setSearchS1(''));//----изменение стейта на странице
        dispatch(setSearchS2(''));//----изменение стейта для запроса в бэк
        inputRef.current.focus();//-----посел очистки сфокусироваться на строку поиска
    }

    const updateSearchValue = React.useCallback(
        debounce((str) => {
            dispatch(setSearchS2(str))
        }, 500),
        [],
    );





    const onChangeInput = (event) => {
        dispatch(setSearchS1(event.target.value));
        updateSearchValue((event.target.value));
    }

    return (<div className={styles.root}>
        <svg className={styles.icon} enableBackground="new 0 0 32 32" height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlns="http://www.w3.org/2000/svg" ><g><path d="M13,2C6.935,2,2,6.935,2,13s4.935,11,11,11s11-4.935,11-11S19.065,2,13,2z M13,22c-4.962,0-9-4.037-9-9   c0-4.962,4.038-9,9-9c4.963,0,9,4.038,9,9C22,17.963,17.963,22,13,22z" /><path d="M29.707,28.293l-6.001-6c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l6.001,6C28.488,29.902,28.744,30,29,30   s0.512-0.098,0.707-0.293C30.098,29.316,30.098,28.684,29.707,28.293z" /></g></svg>
        <input ref={inputRef} value={searchValueS1} onChange={onChangeInput} className={styles.input} placeholder="Поиск пиццы ..." />
        {searchValueS1 && (<svg onClick={onClickClear} className={styles.iconClose} height="512px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg" ><path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>)}
    </div>)
}
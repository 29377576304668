import React from 'react';
import { NotFound } from '../components/NotFound';

export const NotFoundPage = () => {
  return (
    <>
      <NotFound />
    </>
  );
};

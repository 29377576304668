import React from 'react';
import styles from './NotFound.module.scss';

export const NotFound = () => {
  return (
    <div className={styles.root}>
      <h1>
        <span>😕</span>
        <br />
        Ничего не найдено <p>К сожалению данная страница отсутствует в нашем интернет магазине!</p>
      </h1>
    </div>
  );
};

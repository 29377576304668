import React from "react";
import styles from './Pagination.module.scss'
import { useSelector, useDispatch } from "react-redux";
import { setPages } from '../../redux/slices/filterSlice'

export const Pagination = () => {
    const pages = useSelector(state => state.filter.pages);//----------------------состояние страниц
    const limits = useSelector((state) => state.filter.limits);
    const filteredItems = useSelector((state) => state.filter.filteredItems);
    const dispatch = useDispatch();

    const numbPage = Math.ceil(filteredItems / limits);
    const numbers = [...Array(numbPage)];

    console.log('nen', numbers);

    return (/**/
        //  {[...new Array(Math.round(items.length / 4)).map((obj) => (<li>{obj}</li>))]} 
        //https://www.youtube.com/watch?v=MxXZCD0XF2k
        //{items.map((obj, i) => (<li key={i} onClick={() => dispatch(setPages(obj))}>{obj}</li>))}
        // {[...new Array(count).map((obj, i) => (<li className={styles.lishka} key={i} onClick={() => dispatch(setPages(obj))}>{pages}</li>))]}
        <ul className={styles.root}>
            <li onClick={() => dispatch(setPages(pages !== 1 ? pages - 1 : 1))}>{"<"}</li>
            {
                numbers.map((num, i) => (<li className={i + 1 === pages ? styles.active : styles.nonActive} key={i} onClick={() => dispatch(setPages(i + 1))}>{i + 1}</li>))
            }
            <li onClick={() => dispatch(setPages(pages === numbPage ? numbPage : pages + 1))}>{">"}</li>
        </ul >

    );


}


import React from 'react';
//------------------------components--------------------------------------------------------------------------
import Categories from '../components/Categoties';
import Sort from '../components/Sort';
import { Limits } from '../components/Limits';
import { Pagination } from '../components/Pagination';
import PizzaBlock from '../components/PizzaBlock';
import Skeleton from '../components/PizzaBlock/Skeleton';
import { listSort } from '../components/Sort'
import qs from 'qs';//----------вшивания в адресную строку запрос
import { useNavigate } from 'react-router-dom';

import axios from 'axios';//---более учшая замен fetch, хотя и так норм было
//------------------------redux-------------------------------------------------------------------------------
import { useSelector, useDispatch } from 'react-redux';
import { setItems, setFilteredItems, setFilters } from '../redux/slices/filterSlice';

export const Home = () => {

  const navigate = useNavigate();
  //----------------------Глобальные стейты----------------------------------------------------------------
  const dispatch = useDispatch();

  const categoryId = useSelector(state => state.filter.categoryId);//----------------------состояние категории
  const sortType = useSelector(state => state.filter.sort.sortType);//----------------------состояние сортировки
  const searchValue = useSelector(state => state.filter.searchS2);//----------------------состояние поиска
  const limits = useSelector(state => state.filter.limits);//----------------------состояние лимита
  const items = useSelector(state => state.filter.items);//----------------------состояние пицц
  const pages = useSelector(state => state.filter.pages);//----------------------состояние страниц
  //----------------------Локальные состояния (states)--------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const fetchPizzas = () => {
    setLoading(true);
    const sortBy = (sortType || '').replace('+', '');
    const order = (sortType || '').includes('+') ? 'asc' : 'desc';
    const category = categoryId > 0 ? `category=${categoryId}` : '';
    const search = searchValue ? `&search=${searchValue}` : '';
    const limit = limits ? `&limit=${limits}` : '';
    const page = pages ? `&page=${pages}` : '';

    axios.get(
      `https://64749fc27de100807b1b63f9.mockapi.io/items?${category}&sortBy=${sortBy}&order=${order}${search}${limit}${page}`
    )
      .then((arr) => dispatch(setItems(arr.data)));

    axios.get(
      `https://64749fc27de100807b1b63f9.mockapi.io/items?${category}&sortBy=${sortBy}&order=${order}${search}`
    )
      .then((arr) => dispatch(setFilteredItems(arr.data.length)));

    /*fetch(
      `https://64749fc27de100807b1b63f9.mockapi.io/items?${category}&sortBy=${sortBy}&order=${order}${search}`
    )
      .then((res) => res.json())
      .then((arr) => dispatch(setFilteredItems(arr.length)));*/

    setLoading(false);
  }

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));
      const sort = listSort.find((obj) => obj.sortType === params.sortType);
      dispatch(setFilters({ ...params, sort }));
      console.log('effect', params, sort);
      isSearch.current = true;

    }
  }, []);


  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!isSearch.current) {
      fetchPizzas();
    }
    isSearch.current = false;
  }, [categoryId, sortType, searchValue, limits, pages, dispatch]);

  //----------Это для вшивания фильтра в строку поиска/запроса
  React.useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        categoryId, sortType, searchValue, limits, pages,
      })
      navigate(`?${queryString}`);
    }

    isMounted.current = true;

  }, [categoryId, sortType, searchValue, limits, pages, dispatch, navigate]);




  const pizzas = items.map((obj) => (<PizzaBlock key={obj.id} {...obj} />));
  const skeletons = [...new Array(6).map((_, index) => <Skeleton key={index} />)];

  /* -----если данные пицц например статичны то можно локально профильтровать ---------
  const pizzas = items.filter(obj => {if (obj.title.toLowerCase().includes(searchValue.toLowerCase())) { return true; } return false;
    }).map((obj) => (<PizzaBlock key={obj.id} {...obj} />)); */
  return (
    <>
      <div className="content__top">
        <Categories />
        {/* это также можно написать вот так =>>> {Categories()} */}
        <Limits />
        <Sort />
      </div>
      <h2 className="content__title">Все пиццы</h2>
      <div className="content__items">
        {loading ? skeletons : pizzas /* <--- это js spread оператор  https://learn.javascript.ru/rest-parameters-spread-operator */
          /* Если мы не уверены что названия атрибутов в базе данных и пробсов компоненте совпадают нужно писать так
  price={obj.price}
  title={obj.title}
  imageUrl={obj.imageUrl}
  counter="0"
  sizes={obj.sizes}
  types={obj.types} 
  ---- и нужно писать так еще pizzas.map((obj) 
  */
        }
        {/*  {practis.map((obj) => (
    <PractisBlock
      name={obj.name}
      age={obj.age}
      city={obj.sity}
      sport={obj.sport}
      imageUrl={obj.imageUrl}
    />
  ))} */}


      </div>
      <Pagination />
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryId: 0,
    sort: {
        name: 'популярности', sortType: 'raiting'
    },
    searchS1: '',
    searchS2: '',
    limits: 8,
    items: [],
    pages: 1,
    filteredItems: '',
}

export const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        setSort(state, action) {
            state.sort = action.payload;
        },
        setSearchS1(state, action) {
            state.searchS1 = action.payload;
        },
        setSearchS2(state, action) {
            state.searchS2 = action.payload;
        },
        setlimits(state, action) {
            state.limits = action.payload;
        },
        setItems(state, action) {
            state.items = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setFilteredItems(state, action) {
            state.filteredItems = action.payload;
        },
        //----------это для вшивания фильтров в windows.location.search
        setFilters(state, action) {
            state.pages = Number(action.payload.pages);
            state.limits = Number(action.payload.limits);
            state.sort = String(action.payload.sort);
            // state.sort.name = action.payload.sort.name;
            state.categoryId = Number(action.payload.categoryId);
            state.searchS2 = Number(action.payload.searchValue);
        },

    },
});

// Action creators are generated for each case reducer function
export const { setCategoryId, setSort, setSearchS1, setSearchS2, setlimits, setItems, setPages, setFilteredItems, setFilters } = filterSlice.actions;

export default filterSlice.reducer;
import React from 'react';
import ContentLoader from 'react-content-loader';
/* Создается с помощью на сайте https://skeletonreact.com/ */
const MyLoader = (props) => (
  <ContentLoader
    className="pizza-block"
    speed={2}
    width={280}
    height={467}
    viewBox="0 0 280 467"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="140" cy="140" r="125" />
    <rect x="0" y="301" rx="0" ry="0" width="280" height="20" />
    <rect x="0" y="329" rx="0" ry="0" width="280" height="88" />
    <rect x="0" y="423" rx="0" ry="0" width="130" height="40" />
    <rect x="140" y="427" rx="10" ry="10" width="120" height="32" />
  </ContentLoader>
);

export default MyLoader;

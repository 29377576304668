import React from "react";
const CartItem = (id, title, type, size, price, count, imageUrl) => {
    return (<div className="cart__item">
        <div className="cart__item-img">
            <img className="pizza-block__image" src="" alt="fef" />
        </div>
        <div className="cart__item">
            <h3>{title}</h3>
            <p>{type}, {size} см.</p>
        </div>
    </div>);
}

export default CartItem;
import React from "react";
import styles from "./Limits.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setlimits } from '../../redux/slices/filterSlice';


export const Limits = () => {
    const limits = useSelector(state => state.filter.limits)//----------------------состояние лимита
    const dispatch = useDispatch();

    const listLimits = [4, 8, 16];

    return (<ul className={styles.root}>
        {listLimits.map((obj, i) => (<li className={obj === limits ? `${styles.active}` : ''} key={i} onClick={() => dispatch(setlimits(obj))}>{obj}</li>))}
    </ul>);
}
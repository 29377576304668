import React from 'react';
import './scss/app.scss';

//----------------База данных-------------------------------------------------------------------------
/* import items from './assets/Pizzas.json';  это в том случаи если какие то данные лежат на фронтенде*/
/* import practis from './assets/practis.json'; */
//----------------Блоки-------------------------------------------------------------------------------
import Header from './components/Header';
import { Home } from './pages/Home';
import { Cart } from './pages/Cart';
import { NotFoundPage } from './pages/NotFound';
import { Route, Routes } from 'react-router-dom';

/* import PractisBlock from './components/CategoriesPractis/Categories practis'; это так жай практика*/

//----------------Вся страница------------------------------------------------------------------------
function App() {

  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <div className="content">
          <div className="container">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/cart" element={<Cart />}></Route>
              <Route path="*" element={<NotFoundPage />}></Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

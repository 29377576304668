import React from 'react';
//----------Redux
import { useSelector, useDispatch } from 'react-redux';
import { setCategoryId } from '../redux/slices/filterSlice';
//----------Сам компонент
function Categories() {
  //----------Redux state & action
  const categoryId = useSelector((state) => state.filter.categoryId)
  const dispatch = useDispatch();
  const onClickCategory = (i) => { dispatch(setCategoryId(i)); }
  //----------Названия категорий
  const categoriesMassiv = ['Все', 'Мясные', 'Вегетарианская', 'Гриль', 'Острые', 'Закрытые'];
  //----------Возвращает компонент в html
  return (
    <div className="categories">
      <ul>
        {categoriesMassiv.map((categoryName, i) => {
          return (
            <li key={i} onClick={() => onClickCategory(i)} className={categoryId === i ? 'active' : ''}>
              {categoryName}
            </li>
          );
        })}
      </ul>
    </div>
  );
  /*   сначало можем начать так чтобы завязать логику, это Я для примера оставил <div className="categories">
      <ul>
        <li onClick={(i) => onClickCategory(0)} className={categoryId === 0 ? 'active' : ''}>
          Все
        </li>
        <li onClick={(i) => onClickCategory(1)} className={categoryId === 1 ? 'active' : ''}>
          Мясные
        </li>
        <li onClick={(i) => onClickCategory(2)} className={categoryId === 2 ? 'active' : ''}>
          Вегетарианская
        </li>
        <li onClick={(i) => onClickCategory(3)} className={categoryId === 3 ? 'active' : ''}>
          Гриль
        </li>
        <li onClick={(i) => onClickCategory(4)} className={categoryId === 4 ? 'active' : ''}>
          Острые
        </li>
        <li onClick={(i) => onClickCategory(5)} className={categoryId === 5 ? 'active' : ''}>
          Закрытые
        </li>
      </ul> */
}

export default Categories;

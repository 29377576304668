import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItem } from '../../redux/slices/cartSlice'
const pizzaTypes = ['тонкое', 'традиционное'];
//const pizzaSizes = ['26', '30', '40'];

function PizzaBlock({ id, title, price, imageUrl, types, sizes }) {

  const dispatch = useDispatch();
  //-------------------------Хуки---------------------------------------------------------------------------

  const [typeStart, setTypeStart] = React.useState(0);
  const [sizeStart, setSizeStart] = React.useState(0);

  const cartItem = useSelector(state => state.cart.items.find(obj => obj.id === id));
  const addedCount = cartItem ? cartItem.count : 0;

  const onClickAdd = () => {
    const item = {
      id, title, price, imageUrl, type: pizzaTypes[typeStart], sizes: sizeStart,
    };
    dispatch(addItem(item));
  }

  //-------------------------Компонент который возращается в app.js --------------------------------------
  return (
    <div className="pizza-block">
      <img className="pizza-block__image" src={imageUrl} alt="Pizza" />
      <h4 className="pizza-block__title">{title}</h4>
      <div className="pizza-block__selector">
        <ul>
          {types.map((obj) => (
            <li
              key={obj}
              onClick={() => setTypeStart(obj)}
              className={typeStart === obj ? 'active' : ''}
            >
              {pizzaTypes[obj]}
            </li>
            /*  есть длинный способ через создание ещё и функции const setTypeStartFunc = (obj) => {setTypeStart(obj);};
            <li onClick={() => setTypeStartFunc(obj)} className={typeStart === obj ? 'active' : ''}> {pizzaTypes[obj]} </li> 
            так делают когда на onClick навешиваются 2 и более команды */
          ))}
        </ul>
        <ul>
          {sizes.map((sizesName, i) => (
            <li key={i} onClick={() => setSizeStart(i)} className={sizeStart === i ? 'active' : ''}>
              {sizesName} см.
            </li>
          ))}
        </ul>
      </div>
      <div className="pizza-block__bottom">
        <div className="pizza-block__price">от {price} ₽</div>
        <button onClick={onClickAdd} className="button button--outline button--add">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"
            />
          </svg>
          <span >Добавить</span>
          {addedCount > 0 && <i>{addedCount}</i>}
        </button>
      </div>
    </div>
  );
}

export default PizzaBlock;
